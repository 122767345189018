import React, { FC } from "react";

const Logo: FC<{ size: string }> = ({ size }) => (
  <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.61 300">
    <path
      d="M380.62,164.3a23.43,23.43,0,0,1-1.6,8.56,21.73,21.73,0,0,1-4.71,7.33,23.54,23.54,0,0,1-7.65,5.16,26.44,26.44,0,0,1-10.55,2A28,28,0,0,1,346,185.56a22.79,22.79,0,0,1-7.86-5,22.15,22.15,0,0,1-5.12-8,29.78,29.78,0,0,1-1.81-10.63A58.44,58.44,0,0,1,334,143.55a50.31,50.31,0,0,1,8-15.28,42.12,42.12,0,0,1,12.21-10.73,37.81,37.81,0,0,1,15.57-5l1.45,6.6A32.86,32.86,0,0,0,359.31,123a36.43,36.43,0,0,0-9.44,7.43,39.7,39.7,0,0,0-6.56,9.81,36.4,36.4,0,0,0-3.25,10.94,12.92,12.92,0,0,1,2.43-2.79,16.19,16.19,0,0,1,3.81-2.53,26.93,26.93,0,0,1,5-1.8,23.21,23.21,0,0,1,5.93-.73,29.9,29.9,0,0,1,9.81,1.5,21.37,21.37,0,0,1,7.38,4.23,17.9,17.9,0,0,1,4.64,6.61A22.29,22.29,0,0,1,380.62,164.3Zm-8.56.82q0-7.23-4-11t-12.34-3.82a20.6,20.6,0,0,0-5.56.72,20.15,20.15,0,0,0-4.63,1.91,16.92,16.92,0,0,0-3.6,2.74,13.3,13.3,0,0,0-2.37,3.09,18.33,18.33,0,0,0-.2,2.27v2a19.91,19.91,0,0,0,1,6.25,16.28,16.28,0,0,0,3.14,5.62,16,16,0,0,0,5.29,4,17.07,17.07,0,0,0,7.51,1.55,16,16,0,0,0,11.37-4.55,14,14,0,0,0,3.24-4.85A15.36,15.36,0,0,0,372.06,165.12Z"
      fill="teal"
    />
    <path
      d="M394.55,168.53q0-12.5,15.18-20.13-2.89-1.54-5.37-3.2a21.25,21.25,0,0,1-4.28-3.72,15.5,15.5,0,0,1-2.84-4.74,17.14,17.14,0,0,1-1-6.2,16.63,16.63,0,0,1,1.54-7.12,16.17,16.17,0,0,1,4.44-5.67,22,22,0,0,1,7-3.77,30.92,30.92,0,0,1,17.8-.16,20.13,20.13,0,0,1,6.61,3.46,15.21,15.21,0,0,1,4.18,5.21,14.6,14.6,0,0,1,1.45,6.4,20.59,20.59,0,0,1-2.95,10.79,27.68,27.68,0,0,1-9.44,8.93,54.17,54.17,0,0,1,5.57,3.3,23.8,23.8,0,0,1,4.49,3.92,16.74,16.74,0,0,1,3,4.9A17,17,0,0,1,441,167a21.13,21.13,0,0,1-1.55,8.1,17.18,17.18,0,0,1-4.59,6.45,22.27,22.27,0,0,1-7.48,4.23,31.4,31.4,0,0,1-10.33,1.55,28.2,28.2,0,0,1-9.7-1.55,21.15,21.15,0,0,1-7.07-4.13,17,17,0,0,1-4.28-6A18.13,18.13,0,0,1,394.55,168.53Zm38.3-1a10.12,10.12,0,0,0-1.4-5.31,15.67,15.67,0,0,0-3.71-4.18,32.41,32.41,0,0,0-5.32-3.41c-2-1-4.06-2.06-6.19-3.09q-7.44,3.62-10.58,7.94t-3.15,8.16a12.54,12.54,0,0,0,1,4.9,11.75,11.75,0,0,0,2.89,4.08,13.83,13.83,0,0,0,4.75,2.78,19.07,19.07,0,0,0,6.55,1,19.7,19.7,0,0,0,5.73-.83,15.24,15.24,0,0,0,4.85-2.43,11.53,11.53,0,0,0,3.36-4A12.29,12.29,0,0,0,432.85,167.49Zm-28.49-37.26a10,10,0,0,0,1.33,5.17,15.24,15.24,0,0,0,3.54,4.07,29.11,29.11,0,0,0,5.08,3.31q2.87,1.49,6.05,2.94a33,33,0,0,0,8.2-7.23,12.85,12.85,0,0,0,2.74-8,9.57,9.57,0,0,0-1.08-4.59,11.27,11.27,0,0,0-2.88-3.46,13.28,13.28,0,0,0-4.16-2.22,16,16,0,0,0-4.94-.77,17.16,17.16,0,0,0-5.86.93,13.24,13.24,0,0,0-4.32,2.48,11.65,11.65,0,0,0-2.72,3.45A8.36,8.36,0,0,0,404.36,130.23Z"
      fill="teal"
    />
    <path
      d="M451.22,113.82h16.72v42.73h5.67L494,134.47h9.7l-22.81,24.46,18.78,20.23h6.71v6.91H495.7l-22.09-23.32h-5.67v23.32H460V120.74h-8.77Z"
      fill="teal"
    />
    <path d="M557.94,121.67a19,19,0,0,0-3.89-1.09,31.11,31.11,0,0,0-5.12-.36,23.46,23.46,0,0,0-8.34,1.55,18.56,18.56,0,0,0-7.25,5.06,26.09,26.09,0,0,0-5.07,9.18A43.92,43.92,0,0,0,526.35,150a44.43,44.43,0,0,0,1.71,13,26.78,26.78,0,0,0,4.77,9.29,19.26,19.26,0,0,0,7.35,5.58,24,24,0,0,0,9.57,1.85,25.59,25.59,0,0,0,5-.46,22.3,22.3,0,0,0,4.3-1.29V167.6h7.64v13.93l.1.21a26.86,26.86,0,0,1-7.84,4,36.4,36.4,0,0,1-11.56,1.6,31.89,31.89,0,0,1-12-2.22,25,25,0,0,1-9.55-6.76,31.4,31.4,0,0,1-6.25-11.61A56.25,56.25,0,0,1,517.38,150a52.41,52.41,0,0,1,2.47-17.19,32.11,32.11,0,0,1,6.71-11.56,25.52,25.52,0,0,1,9.86-6.55,34.54,34.54,0,0,1,12-2.06q3.51,0,6.09.25a36.33,36.33,0,0,1,4.54.73,17.08,17.08,0,0,1,3.46,1.18,30.52,30.52,0,0,1,2.94,1.65h.1v16.31h-7.63Z" />
    <path d="M578.48,160.27q0-12.5,6.5-19.77t18.58-7.27a27.17,27.17,0,0,1,11.2,2.11,22.12,22.12,0,0,1,7.79,5.73,23.5,23.5,0,0,1,4.59,8.57,36.51,36.51,0,0,1,1.5,10.63,35.67,35.67,0,0,1-1.65,11.2,23.39,23.39,0,0,1-4.85,8.51,21.48,21.48,0,0,1-7.9,5.42,28.21,28.21,0,0,1-10.68,1.91,27.35,27.35,0,0,1-11.15-2.11,21.87,21.87,0,0,1-7.84-5.73,23.37,23.37,0,0,1-4.6-8.57A36.51,36.51,0,0,1,578.48,160.27Zm8.56,0a30.16,30.16,0,0,0,.88,7.22,19.67,19.67,0,0,0,2.83,6.51,14.76,14.76,0,0,0,5.09,4.64,15.51,15.51,0,0,0,7.67,1.76q8.22,0,12.39-5.11t4.17-15a30.1,30.1,0,0,0-.88-7.28,19.21,19.21,0,0,0-2.88-6.45,15.19,15.19,0,0,0-5.14-4.64,15.49,15.49,0,0,0-7.66-1.76q-8.24,0-12.35,5.06T587,160.27Z" />
    <path d="M671.88,134.47h14.76v35.19a73.8,73.8,0,0,0,.62,9.7h6.92v6.71H680.35L680,177.3h-.42a21.5,21.5,0,0,1-7.1,7.22,19.67,19.67,0,0,1-10.7,2.79,26.47,26.47,0,0,1-8.08-1.08,11.54,11.54,0,0,1-5.51-3.77,17.62,17.62,0,0,1-3.19-7.17,51.65,51.65,0,0,1-1-11.2V141.38H637.1v-6.91h15.07v28a71.52,71.52,0,0,0,.36,7.58A17,17,0,0,0,654,175.7a7.66,7.66,0,0,0,3.33,3.51,12.46,12.46,0,0,0,5.89,1.19,13.5,13.5,0,0,0,9.27-3.51,22.57,22.57,0,0,0,6-8.78V141.38h-6.61Z" />
    <path d="M706,152.53c0-.62,0-1.43,0-2.43s-.09-2-.15-3.09-.16-2.12-.26-3.15-.23-1.93-.36-2.68H698.3v-6.71h13.83l.93,8.36h.52a17.17,17.17,0,0,1,2.73-3.46,21.39,21.39,0,0,1,9-5.32,20.65,20.65,0,0,1,5.89-.82,31,31,0,0,1,8.41,1,12.89,12.89,0,0,1,6.09,3.66,17,17,0,0,1,3.66,7.13,43.58,43.58,0,0,1,1.24,11.4v29.62h-8.15v-28c0-5.69-.93-10-2.78-12.87s-5.21-4.32-10.08-4.32a13.05,13.05,0,0,0-5.19,1.08,17.81,17.81,0,0,0-4.58,2.83,19.53,19.53,0,0,0-3.6,4.07,16.94,16.94,0,0,0-2.26,4.89v32.33h-8Z" />
    <path d="M761.57,134.47h12.28V124.25L782,122v12.49h27.55v6.91H782v24.36q0,7.53,3.65,11.15t10.44,3.61a17.62,17.62,0,0,0,8.08-1.76,44.34,44.34,0,0,0,6.22-3.81l2.67,6.08a29,29,0,0,1-8.61,4.65,30.87,30.87,0,0,1-10.27,1.75,25.22,25.22,0,0,1-7.8-1.18,17.94,17.94,0,0,1-6.45-3.67,17.6,17.6,0,0,1-4.44-6.34,23.48,23.48,0,0,1-1.65-9.24v-25.6H761.57Z" />
    <path d="M874.9,179.57a26.77,26.77,0,0,1-4.39,3.1,32.3,32.3,0,0,1-5.52,2.47,40.57,40.57,0,0,1-6.25,1.6,37.79,37.79,0,0,1-6.55.57,29.19,29.19,0,0,1-10.94-1.91,21,21,0,0,1-8-5.47,23.84,23.84,0,0,1-4.85-8.51,35.21,35.21,0,0,1-1.65-11.15,34.09,34.09,0,0,1,1.81-11.56,22.38,22.38,0,0,1,5.21-8.46,22.8,22.8,0,0,1,8.25-5.22,31,31,0,0,1,10.84-1.8,33.23,33.23,0,0,1,8.57,1.13,17,17,0,0,1,7.48,4.29,20.41,20.41,0,0,1,5,8.67q1.75,5.52,1.24,14.29H835.37q0,9.28,5,13.88t13.33,4.6a22.9,22.9,0,0,0,5.51-.68,46.46,46.46,0,0,0,5.2-1.59,27.36,27.36,0,0,0,4.37-2.07,18.8,18.8,0,0,0,2.93-2.06ZM853.17,140a27.72,27.72,0,0,0-6.43.72,14.47,14.47,0,0,0-5.35,2.43,13.11,13.11,0,0,0-3.81,4.44,17.61,17.61,0,0,0-1.9,6.76h32q-.51-6.82-4.37-10.58A13.89,13.89,0,0,0,853.17,140Z" />
    <path d="M889,186.07v-6.91H903V141.38H889v-6.91h19.31l1.65,6.71h.51a36.86,36.86,0,0,1,7.85-5.47,21.89,21.89,0,0,1,10.32-2.28,10.32,10.32,0,0,1,5.62,1.35,8.94,8.94,0,0,1,3.31,3.92,20.59,20.59,0,0,1,1.6,6.24,76.29,76.29,0,0,1,.41,8.31l-7.23.1q0-6.54-1.29-9.62a4.68,4.68,0,0,0-4.69-3.07,16.93,16.93,0,0,0-5.78.93,18.67,18.67,0,0,0-4.49,2.26,23.1,23.1,0,0,0-3.25,2.68,15.2,15.2,0,0,0-2,2.26v30.37h19.81v6.91Z" />
    <circle cx="150" cy="150" r="150" fill="teal" />
    <path
      d="M.05,146.1v7.8a149.26,149.26,0,0,0,18.78,68.9H281.17a150.19,150.19,0,0,0,.73-144.29H18.1A149.2,149.2,0,0,0,.05,146.1Z"
      fill="#fff"
    />
    <path
      d="M3.48,117.8h293c-.42-1.94-.88-3.86-1.37-5.78H4.85C4.36,113.94,3.9,115.86,3.48,117.8Z"
      fill="teal"
    />
    <rect x="0.05" y="147.88" width="299.88" height="5.78" fill="teal" />
    <path
      d="M296.18,183.78H3.82q.67,2.91,1.46,5.78H294.72Q295.5,186.7,296.18,183.78Z"
      fill="teal"
    />
    <g opacity="0.5">
      <polygon
        points="291.08 228.8 266.09 228.8 266.09 252.28 273.76 252.28 273.76 269.87 283.15 269.87 283.15 252.28 291.08 252.28 291.08 228.8"
        fill="#fff"
      />
      <polygon
        points="223.24 252.28 231.05 252.28 231.05 269.87 240.44 269.87 240.44 252.28 248.24 252.28 248.24 228.8 223.24 228.8 223.24 252.28"
        fill="#fff"
      />
      <polygon
        points="180.4 252.28 188.2 252.28 188.2 269.87 197.59 269.87 197.59 252.28 205.39 252.28 205.39 228.8 180.4 228.8 180.4 252.28"
        fill="#fff"
      />
      <polygon
        points="137.55 252.28 145.36 252.28 145.36 269.87 154.75 269.87 154.75 252.28 162.55 252.28 162.55 228.8 137.55 228.8 137.55 252.28"
        fill="#fff"
      />
      <polygon
        points="94.71 252.28 102.51 252.28 102.51 269.87 111.9 269.87 111.9 252.28 119.7 252.28 119.7 228.8 94.71 228.8 94.71 252.28"
        fill="#fff"
      />
      <polygon
        points="52 252.28 59.66 252.28 59.66 269.87 69.05 269.87 69.05 252.28 76.99 252.28 76.99 228.8 52 228.8 52 252.28"
        fill="#fff"
      />
      <polygon
        points="9.15 252.28 16.95 252.28 16.95 269.87 26.34 269.87 26.34 252.28 34.14 252.28 34.14 228.8 9.15 228.8 9.15 252.28"
        fill="#fff"
      />
    </g>
    <g opacity="0.5">
      <polygon
        points="283.15 47.54 283.15 29.76 273.76 29.76 273.76 47.54 266.09 47.54 266.09 71.3 291.08 71.3 291.08 47.54 283.15 47.54"
        fill="#fff"
      />
      <polygon
        points="240.44 29.76 231.05 29.76 231.05 47.54 223.24 47.54 223.24 71.3 248.24 71.3 248.24 47.54 240.44 47.54 240.44 29.76"
        fill="#fff"
      />
      <polygon
        points="197.59 29.76 188.2 29.76 188.2 47.54 180.4 47.54 180.4 71.3 205.39 71.3 205.39 47.54 197.59 47.54 197.59 29.76"
        fill="#fff"
      />
      <polygon
        points="154.75 29.76 145.36 29.76 145.36 47.54 137.55 47.54 137.55 71.3 162.55 71.3 162.55 47.54 154.75 47.54 154.75 29.76"
        fill="#fff"
      />
      <polygon
        points="111.9 29.76 102.51 29.76 102.51 47.54 94.71 47.54 94.71 71.3 119.7 71.3 119.7 47.54 111.9 47.54 111.9 29.76"
        fill="#fff"
      />
      <polygon
        points="69.05 29.76 59.66 29.76 59.66 47.54 52 47.54 52 71.3 76.99 71.3 76.99 47.54 69.05 47.54 69.05 29.76"
        fill="#fff"
      />
      <polygon
        points="26.34 29.76 16.95 29.76 16.95 47.54 9.15 47.54 9.15 71.3 34.14 71.3 34.14 47.54 26.34 47.54 26.34 29.76"
        fill="#fff"
      />
    </g>
    <path
      d="M36.05,163.07A13.18,13.18,0,1,1,49.23,149.9,13.21,13.21,0,0,1,36.05,163.07Z"
      fill="teal"
    />
    <path
      d="M36.05,127.06a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,36.05,127.06Z"
      fill="teal"
    />
    <path
      d="M36.05,199.09a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,36.05,199.09Z"
      fill="teal"
    />
    <path
      d="M67.41,127.06a13.18,13.18,0,1,1,13.18-13.18A13.22,13.22,0,0,1,67.41,127.06Z"
      fill="teal"
    />
    <path
      d="M98.76,127.06a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,98.76,127.06Z"
      fill="teal"
    />
    <path
      d="M67.41,163.07A13.18,13.18,0,1,1,80.59,149.9,13.22,13.22,0,0,1,67.41,163.07Z"
      fill="teal"
    />
    <path
      d="M232.18,199.09a13.18,13.18,0,1,1,13.18-13.18A13.22,13.22,0,0,1,232.18,199.09Z"
      fill="teal"
    />
    <path
      d="M232.18,163.07a13.18,13.18,0,1,1,13.18-13.17A13.22,13.22,0,0,1,232.18,163.07Z"
      fill="teal"
    />
    <path
      d="M232.18,127.06a13.18,13.18,0,1,1,13.18-13.18A13.22,13.22,0,0,1,232.18,127.06Z"
      fill="teal"
    />
    <path
      d="M263.53,199.09a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,263.53,199.09Z"
      fill="teal"
    />
    <path
      d="M263.53,163.07a13.18,13.18,0,1,1,13.18-13.17A13.21,13.21,0,0,1,263.53,163.07Z"
      fill="teal"
    />
    <path
      d="M263.53,127.06a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,263.53,127.06Z"
      fill="teal"
    />
    <path
      d="M200.82,199.09A13.18,13.18,0,1,1,214,185.91,13.21,13.21,0,0,1,200.82,199.09Z"
      fill="teal"
    />
    <path
      d="M200.82,163.07A13.18,13.18,0,1,1,214,149.9,13.21,13.21,0,0,1,200.82,163.07Z"
      fill="teal"
    />
    <path
      d="M200.82,127.06A13.18,13.18,0,1,1,214,113.88,13.21,13.21,0,0,1,200.82,127.06Z"
      fill="teal"
    />
    <path
      d="M169.47,199.09a13.18,13.18,0,1,1,13.18-13.18A13.22,13.22,0,0,1,169.47,199.09Z"
      fill="teal"
    />
    <path
      d="M169.47,163.07a13.18,13.18,0,1,1,13.18-13.17A13.22,13.22,0,0,1,169.47,163.07Z"
      fill="teal"
    />
    <path
      d="M138.11,199.09a13.18,13.18,0,1,1,13.18-13.18A13.21,13.21,0,0,1,138.11,199.09Z"
      fill="teal"
    />
  </svg>
);

export default Logo;
