import React, { FC } from "react";

const Asm: FC<{ size: string }> = ({ size = "1em" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <path
      d="M0 290.523l72.881-3.903v34.1L0 316.148v-25.625zm0 123.802l72.881 4.572v-34.1L0 388.7v25.625zM460.02 94.169l-72.881-3.904v34.1l72.88-4.572V94.17zm-80.905-17.832h-39.392v61.956h39.392V76.337zM81.573 432.825h43.946v-61.956H81.573v61.956zm0-196.355h43.946v-61.955H81.573v61.955zm0-98.177h43.946V76.337H81.574v61.956zm0 196.355h43.946v-61.956H81.573v61.956zM0 119.793l72.881 4.572v-34.1L.001 94.17v25.624zm0 98.177l72.881 4.573v-34.1l-72.88 3.903v25.624zm330.985.973V62.915c0-10.808-8.762-19.57-19.57-19.57H152.591c-10.808 0-19.57 8.762-19.57 19.57v386.17c0 10.808 8.762 19.57 19.57 19.57H252.76l78.225-249.712zm169.151 249.712H512l-65.42-213.948l-49.978 167.448l-49.973-167.447L281.25 468.52h12.035c4.472-17.444 9.451-34.457 16.674-49.715c9.326-20.123 21.22-29.698 35.796-31c19.613-1.753 35.243 18.758 50.817 55.689c15.98-36.31 30.275-56.555 49.784-55.69c17.729.787 30.533 13.357 41.178 41.156c4.636 13.076 8.511 26.425 12.601 39.696zM379.115 230.924v-56.41h-39.392v56.41h39.392zm80.905-38.578l-72.881-3.903v34.1l72.88-4.572v-25.625z"
      fill="currentColor"
    />
  </svg>
);

export default Asm;
